import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57aa8cf4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation-secondary" }
const _hoisted_2 = { class: "navigation-secondary__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorOrganizationsDetailsRootIndexVue', params: { id: _ctx.$route.params.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.details), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorOrganizationsDetailsOrganizationMembersIndexVue', params: { id: _ctx.$route.params.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.organizationMembers), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ])
    ])
  ]))
}