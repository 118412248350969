import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11704cb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-details-root-information" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "box__headline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "action" }
const _hoisted_6 = {
  key: 0,
  class: "box__information"
}
const _hoisted_7 = { class: "box__information-data" }
const _hoisted_8 = { class: "box__information-data__information" }
const _hoisted_9 = { class: "box__information-data__information-item" }
const _hoisted_10 = { class: "box__information-data__information-item__label" }
const _hoisted_11 = { class: "box__information-data__information-item__value" }
const _hoisted_12 = { class: "box__information-data__information-item" }
const _hoisted_13 = { class: "box__information-data__information-item__label" }
const _hoisted_14 = { class: "box__information-data__information-item__value" }
const _hoisted_15 = { class: "box__information-data__information-item" }
const _hoisted_16 = { class: "box__information-data__information-item__label" }
const _hoisted_17 = { class: "box__information-data__information-item__value" }
const _hoisted_18 = { class: "box__information-data__information-item" }
const _hoisted_19 = { class: "box__information-data__information-item__label" }
const _hoisted_20 = { class: "box__information-data__information-item__value" }
const _hoisted_21 = { class: "box__information-data__information-item" }
const _hoisted_22 = { class: "box__information-data__information-item__label" }
const _hoisted_23 = { class: "box__information-data__information-item__value" }
const _hoisted_24 = { class: "box__information-data__information-item" }
const _hoisted_25 = { class: "box__information-data__information-item__label" }
const _hoisted_26 = { class: "box__information-data__information-item__value" }
const _hoisted_27 = {
  key: 1,
  class: "box__remove"
}
const _hoisted_28 = {
  key: 2,
  class: "box__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAdministratorOrganizationsDetailsRootInformationFormUpdateIndexVue = _resolveComponent("AppAdministratorOrganizationsDetailsRootInformationFormUpdateIndexVue")!
  const _component_AppAdministratorOrganizationsDetailsRootInformationFormRemoveIndexVue = _resolveComponent("AppAdministratorOrganizationsDetailsRootInformationFormRemoveIndexVue")!
  const _component_LoadingIndexVue = _resolveComponent("LoadingIndexVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.organization.content.name), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AppAdministratorOrganizationsDetailsRootInformationFormUpdateIndexVue, {
            "organization-information": _ctx.organization,
            onGetOrganization: _ctx.getOrganization
          }, null, 8, ["organization-information", "onGetOrganization"])
        ])
      ]),
      (_ctx.isDataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.translation.address), 1),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getInformation(_ctx.organization.content.address)), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.translation.place), 1),
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.getInformation(_ctx.organization.content.place)), 1)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.translation.country), 1),
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.getInformation(_ctx.organization.content.country)), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.translation.identificationNumber), 1),
                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.getInformation(_ctx.organization.content.identificationNumber)), 1)
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.translation.vatNumber), 1),
                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.getInformation(_ctx.organization.content.vatNumber)), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.translation.phone), 1),
                  _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.getInformation(_ctx.organization.content.phone)), 1)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isDataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
            _createVNode(_component_AppAdministratorOrganizationsDetailsRootInformationFormRemoveIndexVue, { "organization-information": _ctx.organization }, null, 8, ["organization-information"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _createVNode(_component_LoadingIndexVue)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}