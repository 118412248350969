
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppOrganizationIndex} from "@/models/app/organization";
import {ResponseIndex} from "@/models/response";

// Components
import {OfficeBuilding} from "@element-plus/icons-vue";
import AppAdministratorOrganizationsDetailsRootInformationFormUpdateIndexVue from "@/views/app/administrator/organizations/details/root/information/form/update.vue";
import AppAdministratorOrganizationsDetailsRootInformationFormRemoveIndexVue from "@/views/app/administrator/organizations/details/root/information/form/remove.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getInformation} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    OfficeBuilding,
    AppAdministratorOrganizationsDetailsRootInformationFormUpdateIndexVue,
    AppAdministratorOrganizationsDetailsRootInformationFormRemoveIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorOrganizationsDetailsRootInformationIndexVue extends Vue {
  isLoading = false;

  organization: AppOrganizationIndex = new AppOrganizationIndex();

  get isDataLoaded(): boolean {
    return this.organization && this.isLoading === false;
  }

  get translation(): any {
    return getTranslation([
      "address",
      "country",
      "identificationNumber",
      "place",
      "phone",
      "vatNumber",
    ]);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }

  async getOrganization(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/organization`,
      formData: {
        id: this.$route.params.id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.organization = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getOrganization();
  }
}
