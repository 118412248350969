
// Vue
import {Options, Vue} from "vue-class-component";

// Services
import {getTranslation} from "@/services/app/translation";

@Options({})
export default class AdministratorNavigationOrganizationDetailsVue extends Vue {
  get translation(): any {
    return getTranslation([
      "details",
      "organizationMembers",
    ]);
  }
}
