export interface AppOrganizationIndex {
  id: string;
  user_id: string | string[];
  content: {
    name: string;
    address: string;
    place: string;
    country: string;
    identificationNumber: string;
    vatNumber: string;
    phone: string;
  }
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

export class AppOrganizationIndex implements AppOrganizationIndex {
  id: string;
  user_id: string | string[];
  content: {
    name: string;
    address: string;
    place: string;
    country: string;
    identificationNumber: string;
    vatNumber: string;
    phone: string;
  }
  is_active: boolean;
  created_at: string;
  updated_at: string;

  constructor() {
    this.id = "";
    this.user_id = "";
    this.content = {
      name: "",
      address: "",
      place: "",
      country: "",
      identificationNumber: "",
      vatNumber: "",
      phone: "",
    };
    this.is_active = false;
    this.created_at = "";
    this.updated_at = "";
  }
}